import React from 'react'
import Img from 'gatsby-image';

const ImageSection = ({ input, id }) => {
  if (input.columnsPerRow == 0) {
    return input.column.map((c, i) => (
      <Img fluid={c.image.childImageSharp.fluid} className="normal-image" id={id ? id : ""} key={'image_' + i} />
    ))
  }

  const columnClass = input.columnsPerRow == 1 ? 'is-full' : input.columnsPerRow == 2 ? 'is-half' : 'is-third'
  return (
    <section className='section content-section' id={id ? id : ""}>
      <div className="container">
        <div className="columns" style={{ flexWrap: 'wrap' }}>
          {input.column.map((c, i) => (
            <div className={'column ' + columnClass} key={'image_' + i}>
              <Img fluid={c.image.childImageSharp.fluid} />
            </div>
          ))}
        </div>
      </div>
    </section >
  )
}

export default ImageSection
