import React from 'react'

import Content from './dynamic-sections/content'
import ImageSection from './dynamic-sections/image-section'
import HeroImage from './dynamic-sections/hero-image'
import Advanced from './dynamic-sections/advanced'

const DynamicSections = ({ sections }) => {
  return (
    sections
      .sort((s1, s2) => s1.priority > s2.priority ? 1 : -1)
      .map((s, i) => {
        switch (s.columnTypes) {
          case 'content':
            return <Content input={s} id={s.anchor_id ? s.anchor_id : ''} key={'section_' + i} />
          case 'image':
            return <ImageSection input={s} id={s.anchor_id ? s.anchor_id : ''} key={'section_' + i} />
          case 'advanced':
            return <Advanced input={s} id={s.anchor_id ? s.anchor_id : ''} key={'section_' + i} />

          case 'heroImage':
            return <HeroImage input={s} id={s.anchor_id ? s.anchor_id : ''} key={'section_' + i} />

          default:
            return null;
        }
      })
  )
}

export default DynamicSections