import React, { useState } from 'react'
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import close from '../../images/iconfinder_close_1814098.svg'

const Advanced = ({ input, id }) => {

    const [activeMember, setActiveMember] = useState('')

    const columnClass = input.columnsPerRow == 1 ? 'is-full' : input.columnsPerRow == 2 ? 'is-half' : 'is-one-third'

    return (
        <section className='section content-section advanced-section' id={id ? id : ""}>
            <div className="container">
                <StyledContent className="columns is-multiline">
                    {input.column.map((c, i) => {
                        const elementClass = c.columnsPerRow == 1 ? 'is-full' : c.columnsPerRow == 2 ? 'is-half' : 'is-one-third'
                        return (
                            <div className={'column ' + columnClass} key={'advanced_' + i}>
                                {c.title && (
                                    <div className="column is-full title-column">
                                        <hr />
                                        <h3 className='is-size-6 is-uppercase'>{c.title}</h3>
                                    </div>
                                )}
                                <div className="columns is-multiline advanced-columns">
                                    {c.element ?
                                        c.element.map((e, j) => {
                                            if (e.number && e.description) {
                                                return (
                                                    <div className={'column value ' + elementClass} key={'element_' + j}>
                                                        <h3 className="is-size-1">
                                                            {e.number}
                                                        </h3>
                                                        <p className="is-size-7  is-uppercase">
                                                            {e.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                            else if (e.icon && e.description) {
                                                return (
                                                    <div className={'column blurb ' + elementClass} key={'element_' + j}>

                                                        {
                                                            e.icon ?
                                                                <img className="icon is-large" src={e.icon.publicURL} alt={e.description} />
                                                                : <></>
                                                        }
                                                        <p className="is-size-7 is-uppercase">
                                                            {e.description}
                                                        </p>


                                                    </div>
                                                )
                                            }
                                            else if (e.image) {
                                                return (
                                                    <div className={'column ' + elementClass} key={'element_' + j}>
                                                        <Img fluid={e.image.childImageSharp.fluid} style={{ width: '50%', margin: 'auto' }} />

                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <p key={'element_' + j}>
                                                        <strong>
                                                            Your Advanced Content Module is not configured correctly
                                                        </strong>
                                                    </p>
                                                )
                                            }
                                        })
                                        : <></>
                                    }
                                    {c.members ?
                                        <div className="members">
                                            {
                                                c.members
                                                    .sort((m1, m2) => m1.priority > m2.priority ? 1 : -1)
                                                    .filter(m => ! m.hidden)
                                                    .map((m, j) => {
                                                        return (
                                                            <>
                                                                <div className='column is-one-quarter member' key={'element_' + j} style={{ textAlign: 'center', maxWidth: '50%' }}>
                                                                    <a onClick={() => setActiveMember(m.Name)}>
                                                                        <Img fluid={m.avatar.childImageSharp.fluid} />
                                                                    </a>
                                                                    <a onClick={() => setActiveMember(m.Name)}>
                                                                        <h2 className="is-size-7" style={{
                                                                            fontWeight: 500,
                                                                            marginTop: '1em',
                                                                            textTransform: 'uppercase',
                                                                            textAlign: 'left',
                                                                            paddingLeft: '1em'
                                                                        }}>{m.Name}</h2>
                                                                    </a>
                                                                </div>
                                                                <div className={`member-modal ${activeMember == m.Name ? 'show' : ''}`}>
                                                                    <div className="columns is-mobile">
                                                                        <div className="column is-half hide-on-mobile">
                                                                            <Img fluid={m.avatar.childImageSharp.fluid} />
                                                                        </div>
                                                                        <div className="column is-half">
                                                                            <h2 className="is-size-7" style={{ fontWeight: 500, textAlign: 'left', textTransform: 'uppercase' }}>{m.Name}</h2>
                                                                            <p className="is-size-7 is-uppercase" style={{ fontWeight: 500, textAlign: 'left', marginBottom: '1em', color: '#999' }}>
                                                                                {m.Position}
                                                                                <br />
                                                                                <a href={'tel:' + m.Email} style={{color: '#999'}}>{m.Email}</a>
                                                                                <br />
                                                                                <a href={'tel:' + m.phone_number_linkable} style={{ color: '#999' }}>
                                                                                    {m.phone_number}
                                                                                </a>

                                                                            </p>
                                                                            <p className="is-size-7" style={{ textAlign: 'left' }}>
                                                                                {m.Description}
                                                                            </p>



                                                                        </div>
                                                                    </div>
                                                                    <CloseButton className='close' onClick={() => setActiveMember('')}>
                                                                        <img src={close} style={{ width: 40, height: 40, color: '#000' }} />
                                                                    </CloseButton>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                            }
                                        </div>
                                        : <></>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </StyledContent>
            </div>
        </section >
    )
}

const StyledContent = styled('div')`
  flex-wrap: wrap;
  .markdown-container{
    margin: 0 0.5em;
  }
  p{
    margin-bottom: 0.5em;
  }
  .title-column{
      padding-bottom: 5em;
  }
  
  .blurb{
        padding: 0;
        padding-bottom: 4em;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 960px){
            flex-direction: column;
        }
        
        p{
            margin: 0;
            margin-top: 10px;
            text-align: center;
            //text-transform: lowercase;
        }
  }
  .title-column h3{
      font-weight: 500;
  }
  .advanced-columns{
      min-height: 30vh;
    .column{
        text-align:center;
        .is-size-2{
            font-weight: 500;
        }
    }
    .members{
        width: 100%;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
    }
    .member.column{
        text-align: left;
        p{
            font-weight: 500;
        }
        a{
            color: #000;
        }
        padding-bottom: 5vh;
        @media screen and (max-width: 960px){
            padding-bottom: 5vh;
            
        }
        @media screen and (max-width: 768px){
            width: 50%;
            flex-basis: initial;
        }
        
    }
    .value{
        @media screen and (max-width: 960px){
            padding: 2.5em 0.75rem;
        }
    }
  }

    .member-modal{
        top: 0;
        left: 0;
        position: fixed;
        z-index: 999;
        height: 100vh;
        width: 100vw;
        background: #fff;
        display: none;
        justify-content: center;
        align-items: center;
    
        &.show{
            display: flex;
        }
        .columns{
            width: 100vw;
            @media screen and (max-width: 960px){
                padding: 10vh 5vw 0;
            }
        }
        @media screen and (max-width: 767px){
            .hide-on-mobile{
                display: none;
            }
            .columns .column{
                width: 100%;
            }
        }


        @media screen and (min-width: 1024px){
            .columns{
                width: 50vw
            }
        }
  }

  
  
`


const CloseButton = styled('button')`
    position: fixed;
    top: 2.5rem;
    right: 3rem;
    @media screen and (max-width: 960px){
      top: 1.5rem;
      right: 1.5rem;
    }
    background: none;
    border: none;
    padding: 0;
    color: #fff;
    &:hover{
      cursor: pointer;
    }
`

export default Advanced