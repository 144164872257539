import React from 'react';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import Hero from '../components/hero'
import DynamicSections from '../components/dynamicSections';
export default ({data}) => (
  <Layout>
    <Hero
      content={
        <h2 className="title" style={{ textTransform: 'lowercase' }}>
          Privacy policy &amp; terms.
          </h2>
      }
      additionalClasses="is-medium"
    />
    <DynamicSections sections={data.pagedata.sections} />
  </Layout >
);


export const query = graphql`
  query{
    pagedata: strapiPage(title: { eq: "Privacy Policy & Terms" }) {
      sections {
        id
        columnsPerRow
        columnTypes
        priority
        anchor_id
        column {
          content
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 1200, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`