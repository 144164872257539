import React from 'react'
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';

const Content = ({ input, id }) => {
  const columnClass = input.columnsPerRow == 1 ? 'is-full' : input.columnsPerRow == 2 ? 'is-half' : 'is-one-third'
  return (
    <section className='section content-section' id={id ? id : ""}>
      <div className="container">

        <StyledContent className="columns is-multiline">
          {input.column.map((c, i) => (
            <div className={'column ' + columnClass} key={'column_' + i}>
              {c.title && (
                <div className="column-title">
                  <hr />
                  <h3 className='is-size-6 is-uppercase'>{c.title}</h3>
                </div>
              )}
              <ReactMarkdown source={c.content} className={'markdown-container is-size-7'} />
            </div>
          ))
          }
        </StyledContent>
      </div>
    </section >
  )
}

const StyledContent = styled('div')`
  flex-wrap: wrap;
  .markdown-container{
    margin: 0 0.5em;
  }
  p{
    margin-bottom: 0.5em;
    text-align: left;
  }
  


  .markdown-container{
    margin-left: 8px;
  }
`

export default Content