import React from 'react'
import Img from 'gatsby-image';

export default ({ input, id }) => {
    console.log("hero-image input", input)
    //return <p>Hi</p>
    return input.column.map((c, i) => (
        <Img fluid={c.heroImage.childImageSharp.fluid} className="full-height-image" id={id ? id : ""} key={'image_' + i} />
    ))
}

